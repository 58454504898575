/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from 'gatsby'
// import logo from "../images/logo.svg"
import { Location } from '@reach/router'

// import Header from "./header"
import "./layout.css"
import Header from "./header";
import BackgroundImage from "gatsby-background-image";
// import BackgroundImage from "gatsby-background-image";

const Layout = (props) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <>
      <div className="w-full h-10 text-center bg-brand-500" >
              <p className={'text-white text-sm py-2'}><strong>Wake up.</strong> Keep on dreaming.</p>
      </div>
      <Location>
        {({ location }) => {
          console.log(location.pathname)
          return location.pathname !== '/' && <Header headerData={props} siteTitle={data.site.siteMetadata.title}/>
        }}
      </Location>

      <div  className="text-brand-800 relative min-h-screen">
        <main className="pb-16">{props.children}</main>
        <footer className="flex items-center justify-around w-full bg-brand-800 text-white w-full text-center border-t border-grey p-4 absolute h-16 bottom-0 ">
          <Link to={'/policy'}>© {new Date().getFullYear()} Virginia (Pianeta ltd)</Link>
          <div className="flex justify-between">
            {/*<a className="mx-4 hover:text-brand-200" href='https://business.virginia.com/en'>B2B</a>*/}
            <Link className="mx-4 hover:text-brand-200" to={'/tos'}>Terms of Service</Link>
            <Link className="mx-4 hover:text-brand-200" to={'/privacy'}>Privacy Policy</Link>
            <Link className="mx-4 hover:text-brand-200" to={'/about'}>About Us</Link>
          </div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
